import * as React from "react";
import { graphql } from "gatsby";
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { PageId } from "../components/Navigation";
import { getIsVertical } from "../utils/getIsVertical";

import "../styles/rehabilitacijas-programma.css";
import ArrowDown from "../svgComponents/ArrowDown";
import { StaticImage } from "gatsby-plugin-image";
import Slider, { Settings } from "react-slick";
import Arrow from "../svgComponents/Arrow";
import { COLORS } from "../utils/consts";
import BottomCommonSection from "../components/BottomCommonSection";

export default function RehabilitationProgram() {
	const { t } = useTranslation();
	const isVertical = getIsVertical();

	const [selectedSection, setSelectedSection] = React.useState(0);

	return (
		<Layout pageId={PageId.RehabilitationProgram}>
			<Seo
				title={t("seoTitle")}
				description={t("seoDescription")}
			/>
			<div className="contentBlock topTextRehab">
				<h1 className="topText1">{t("topText1")}</h1>
				<h2 className="topText2">{t("topText2")}</h2>
			</div>
			<div className="contentBlock noPadding"  style={{ display: "flex" }}>
				<iframe
					src="https://drive.google.com/file/d/1oLIyibVr7vQj-BODuFI8dizvN61pbGwO/preview"
					style={{ border: "none", overflow: "hidden", marginBottom: "-0.1rem", flexGrow: 1, aspectRatio: "16 / 9" }}
					allowFullScreen={true}
					allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
					loading="lazy"
				></iframe>
			</div>
			<RehabTable selectedSection={selectedSection} setSelectedSection={setSelectedSection} />
			{!isVertical && <SectionDescription section={selectedSection} />}
			<ImageCarousel />
			<BottomCommonSection />
		</Layout>
	);
}

const contentCount: Record<number, number> = {
	1: 12,
	2: 13,
	3: 9,
	4: 14,
};

function RehabTable({ selectedSection, setSelectedSection }: { selectedSection: number, setSelectedSection: (section: number) => void }) {
	const { t } = useTranslation();
	const isVertical = getIsVertical();

	const section1Ref = React.useRef<HTMLDivElement>(null);
	const section2Ref = React.useRef<HTMLDivElement>(null);
	const section3Ref = React.useRef<HTMLDivElement>(null);
	const section4Ref = React.useRef<HTMLDivElement>(null);
	const refs = [section1Ref, section2Ref, section3Ref, section4Ref];

	const [knowMore, setKnowMore] = React.useState(0);

	function getProps(section: number) {
		return {
			onMouseOver: () => {
				if (knowMore !== section) {
					setKnowMore(section);
				}
			},
			onMouseDown: () => setSelectedSection(section),
			className: selectedSection === section ? "activeSection" : "",
			key: "section" + section,
		};
	}

	if (isVertical) {
		return (
			<>
				{[4, 1, 2, 3].map((section) => (
					<div key={section} className="contentBlock sectionsWrapper" ref={refs[section - 1]}>
						<div
							key={section}
							onClick={() => {
								setSelectedSection(section);
								window.setTimeout(() => {
									window.scrollTo({
										top: refs[section - 1].current?.offsetTop,
									});
								}, 100);
							}}
							style={{ position: "relative" }}
						>
							<h3>{t(`tableHeader${section}`)}</h3>
							<p style={{ fontSize: "2.5rem" }}>{t(`description${section}`)}</p>
							<p style={{ opacity: selectedSection !== section ? 1 : 0, fontSize: "2rem", marginTop: "4rem" }}>{t("knowMore")}</p>
						</div>
						{
							selectedSection === section
								&& <SectionDescription section={section} />
						}
					</div>
				))}
			</>
		);
	}

	return (
		<div className="contentBlock sectionsWrapper" style={{ paddingLeft: "3rem", ...(selectedSection ? { paddingBottom: "0" } : {})}}>
			<table className="rehabTable" onMouseOut={() => setKnowMore(0)} style={{ margin: 0 }}>
				<thead>
					<tr>
						{[4, 1, 2, 3].map((section) => (
							<th {...getProps(section)}><h3>{t(`tableHeader${section}`)}</h3></th>
						))}
					</tr>
				</thead>
				<tbody>
					<tr className="descriptionRow">
						{[4, 1, 2, 3].map((section) => (
							<td {...getProps(section)}><p>{t(`description${section}`)}</p></td>
						))}
					</tr>
					<tr className="knowMoreRow">
						{[4, 1, 2, 3].map((section) => (
							<td {...getProps(section)}>
								{
									selectedSection === section
										? <div style={{ display: "flex", justifyContent: "center" }}><ArrowDown style={{ opacity: 0.5, width: "5rem", position: "absolute", bottom: "-1.5rem" }} /></div>
										: <p style={{ opacity: [selectedSection, knowMore].includes(section) ? 1 : 0 }}>{t("knowMore")}</p>
								}
							</td>
						))}
					</tr>
				</tbody>
			</table>
		</div>
	);
}

function SectionDescription({ section }: { section: number }) {
	const { t } = useTranslation();
	const { i18n } = useI18next();
	const isVertical = getIsVertical();

	if (section === 0) {
		return null;
	}

	const sectionContent = [];
	if (i18n.resolvedLanguage === "en" && section === 1) {
		sectionContent.push(
			<>
				<li key="1">{t("section1Content1")}</li>
				<li key="2">{t("section1Content2")}</li>
				<li key="3">{t("section1Content3")}</li>
				<li key="4">{t("section1Content4")}</li>
				<li key="5">{t("section1Content5")}</li>
				<ul>
					<li key="6">{t("section1Content6")}</li>
					<li key="7">{t("section1Content7")}</li>
					<li key="8">{t("section1Content8")}</li>
					<li key="9">{t("section1Content9")}</li>
				</ul>
				<li key="10">{t("section1Content10")}</li>
				<ul>
					<li key="11">{t("section1Content11")}</li>
					<li key="12">{t("section1Content12")}</li>
					<li key="13">{t("section1Content13")}</li>
					<li key="14">{t("section1Content14")}</li>
				</ul>
			</>
		)
	} else if (i18n.resolvedLanguage === "en" && section === 2) {
		sectionContent.push(
			<>
				<li key="1">{t("section2Content1")}</li>
				<li key="2">{t("section2Content2")}</li>
				<li key="3">{t("section2Content3")}</li>
				<li key="4">{t("section2Content4")}</li>
				<li key="5">{t("section2Content5")}</li>
				<ul>
					<li key="6">{t("section2Content6")}</li>
					<li key="7">{t("section2Content7")}</li>
					<li key="8">{t("section2Content8")}</li>
					<li key="9">{t("section2Content9")}</li>
					<li key="10">{t("section2Content10")}</li>
				</ul>
				<li key="11">{t("section2Content11")}</li>
				<ul>
					<li key="12">{t("section2Content12")}</li>
					<li key="13">{t("section2Content13")}</li>
					<li key="14">{t("section2Content14")}</li>
					<li key="15">{t("section2Content15")}</li>
				</ul>
			</>
		)
	} else if (i18n.resolvedLanguage === "en" && section === 3) {
		sectionContent.push(
			<>
				<li key="1">{t("section3Content1")}</li>
				<li key="2">{t("section3Content2")}</li>
				<li key="3">{t("section3Content3")}</li>
				<li key="4">{t("section3Content4")}</li>
				<li key="5">{t("section3Content5")}</li>
				<ul>
					<li key="6">{t("section3Content6")}</li>
					<li key="7">{t("section3Content7")}</li>
					<li key="8">{t("section3Content8")}</li>
				</ul>
				<li key="9">{t("section3Content9")}</li>
				<ul>
					<li key="10">{t("section3Content10")}</li>
					<li key="11">{t("section3Content11")}</li>
				</ul>
			</>
		)
	} else if (i18n.resolvedLanguage === "en" && section === 4) {
		sectionContent.push(
			<>
				<li key="1">{t("section4Content1")}</li>
				<li key="2">{t("section4Content2")}</li>
				<li key="3">{t("section4Content3")}</li>
				<li key="4">{t("section4Content4")}</li>
				<li key="5">{t("section4Content5")}</li>
				<ul>
					<li key="6">{t("section4Content6")}</li>
					<li key="7">{t("section4Content7")}</li>
					<li key="8">{t("section4Content8")}</li>
					<li key="9">{t("section4Content9")}</li>
					<li key="10">{t("section4Content10")}</li>
					<li key="11">{t("section4Content11")}</li>
					<li key="12">{t("section4Content12")}</li>
				</ul>
				<li key="13">{t("section4Content13")}</li>
				<ul>
					<li key="14">{t("section4Content14")}</li>
					<li key="15">{t("section4Content15")}</li>
					<li key="16">{t("section4Content16")}</li>
				</ul>
			</>
		);
	} else {
		for (let i = 1; i <= contentCount[section]; i++) {
			sectionContent.push(<li key={i}>{t(`section${section}Content${i}`)}</li>);
		}
	}

	return (
		<div className={isVertical ? "" : "contentBlock"}>
			<div className="sectionDescription">
				<div className="left">
					<p className="descriptionTopText"><Trans i18nKey={`section${section}Description1`} components={{ bold: <strong /> }} /></p>
					{isVertical && <div className="spacer" />}
					<p className="descriptionTopText">{t(`section${section}Description2`)}</p>
					<div className="spacer" />
					<p className="descriptionTopText"><Trans i18nKey={`section${section}Type`} components={{ bold: <strong /> }} /></p>
					{isVertical && <div className="spacer" />}
					<p className="descriptionTopText"><Trans i18nKey={`section${section}Duration`} components={{ bold: <strong /> }} /></p>
					{isVertical && <div className="spacer" />}
					<p className="descriptionTopText"><Trans i18nKey={`section${section}Price`} components={{ bold: <strong /> }} /></p>
				</div>
				<div>
					<p className="descriptionTitle">{t(`section${section}Content`)}</p>
					<ul>
						{sectionContent}
					</ul>
				</div>
			</div>
			<div className="sectionCommon">
				<div>
					<p className="sidenote">{t("sidenote")}</p>
				</div>
				<div>
					<p className="descriptionTitle">{t("included")}</p>
					<ul>
						<li>{t("included1")}</li>
						<li>{t("included2")}</li>
						<li>{t("included3")}</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

const sliderSettings: Settings = {
	arrows: false,
	accessibility: false,
	dots: true,
};

function ImageCarousel() {
	const isVertical = getIsVertical();
	const sliderRef = React.useRef<Slider>(null);

	const handleCarouselArrowsClick = React.useCallback((isRight: boolean) => {
		if (sliderRef.current) {
			sliderRef.current[isRight ? "slickNext" : "slickPrev"]();
		}
	}, []);

	return (
		<div className="contentBlock noPadding">
			<div className="rureImageCarousel">
				<Slider {...sliderSettings} className="carousel" ref={sliderRef}>
					<StaticImage
						src="../images/rehabilitacijas-programma/1.jpg"
						alt="Rehabilitācijas programma (1)"
						quality={95}
						formats={["auto", "webp", "avif"]}
						placeholder="blurred"
					/>
					<StaticImage
						src="../images/rehabilitacijas-programma/2.jpg"
						alt="Rehabilitācijas programma (2)"
						quality={95}
						formats={["auto", "webp", "avif"]}
						placeholder="blurred"
					/>
					<StaticImage
						src="../images/rehabilitacijas-programma/3.jpg"
						alt="Rehabilitācijas programma (3)"
						quality={95}
						formats={["auto", "webp", "avif"]}
						placeholder="blurred"
					/>
					<StaticImage
						src="../images/rehabilitacijas-programma/4.jpg"
						alt="Rehabilitācijas programma (4)"
						quality={95}
						formats={["auto", "webp", "avif"]}
						placeholder="blurred"
					/>
					<StaticImage
						src="../images/rehabilitacijas-programma/5.jpg"
						alt="Rehabilitācijas programma (5)"
						quality={95}
						formats={["auto", "webp", "avif"]}
						placeholder="blurred"
					/>
					<StaticImage
						src="../images/rehabilitacijas-programma/6.jpg"
						alt="Rehabilitācijas programma (6)"
						quality={95}
						formats={["auto", "webp", "avif"]}
						placeholder="blurred"
					/>
					<StaticImage
						src="../images/rehabilitacijas-programma/7.jpg"
						alt="Rehabilitācijas programma (7)"
						quality={95}
						formats={["auto", "webp", "avif"]}
						placeholder="blurred"
					/>
					<StaticImage
						src="../images/rehabilitacijas-programma/8.jpg"
						alt="Rehabilitācijas programma (8)"
						quality={95}
						formats={["auto", "webp", "avif"]}
						placeholder="blurred"
					/>
					<StaticImage
						src="../images/rehabilitacijas-programma/9.jpg"
						alt="Rehabilitācijas programma (9)"
						quality={95}
						formats={["auto", "webp", "avif"]}
						placeholder="blurred"
					/>
				</Slider>
				{
					!isVertical && (
						<div style={{
							position: "absolute",
							top: 0,
							right: 0,
							bottom: 0,
							left: 0,
							display: "flex",
							justifyContent: "space-between",
							pointerEvents: "none",
						}}>
							<div className="carouselArrowContainer" onClick={() => handleCarouselArrowsClick(false)}>
								<Arrow fill={COLORS.darkGreen} style={{ transform: "rotate(90deg)", height: "2rem" }} />
							</div>
							<div className="carouselArrowContainer" onClick={() => handleCarouselArrowsClick(true)}>
								<Arrow fill={COLORS.darkGreen} style={{ transform: "rotate(-90deg)", height: "2rem" }} />
							</div>
						</div>
					)
				}
			</div>
		</div>
	);
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["rehabilitacijas-programma", "common", "BottomCommonSection"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
